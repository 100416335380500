import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router'
import _ from 'lodash'
// import { isClient } from '../utils/helpers'

const history = import.meta.env.SSR // !isClient
  ? createMemoryHistory(import.meta.env.BASE_URL)
  : createWebHistory(import.meta.env.BASE_URL)

const router = createRouter({
  history,
  routes: [
    {
      path: '/',
      name: 'Hem',
      component: () => import('../views/FrontPage.vue')
    },
    {
      path: '/checkout',
      name: 'Checkout',
      component: () => import('../views/Checkout.vue')
    },
    {
      path: '/varumarken',
      name: 'Brands',
      component: () => import('../views/Brands.vue')
    },
    {
      path: '/varumarke/:brand',
      name: 'Brand',
      component: () => import('../views/ProductCategory.vue')
    },
    {
      path: '/tips-och-rad',
      name: 'Tips & råd - Arkiv',
      component: () => import('../views/Articles.vue')
    },
    {
      path: '/produkter',
      name: 'Produkter',
      component: () => import('../views/ProductCategory.vue')
    },
    {
      path: '/kampanj/:slug',
      name: 'Kampanj',
      component: () => import('../views/Campaign.vue'),
      alias: '/campaign/:slug'
    },
    {
      path: '/tips-och-rad/:slug',
      name: 'Tips & råd',
      component: () => import('../views/ArticleSingle.vue'),
      alias: '/tips-and-advice/:slug'
    },
    {
      path: '/tips-och-rad/kategori/:slug',
      name: 'Tips & råd - Kategori',
      component: () => import('../views/ArticlesCategory.vue')
    },
    {
      path: '/kategori/:id',
      name: 'Kategori',
      component: () => import('../views/ProductCategory.vue')
    },
    {
      path: '/produkt/:id',
      name: 'Produkt',
      component: () => import('../views/SingleProduct.vue'),
      alias: '/product/:id'
    },
    {
      path: '/nyhet/:id',
      name: 'Nyhet',
      component: () => import('../views/Post.vue')
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import('../views/Search.vue')
    },
    {
      path: '/butiker',
      name: 'Map',
      component: () => import('../views/Map.vue')
    },
    { path: '/s/djur-natur-butik/hitta-butik', redirect: { name: 'Map' } },
    { path: '/s/djur-natur-butik', redirect: { name: 'Map' } },
    {
      path: '/s/produkter--em--font-color--red--ny---font---em-',
      redirect: { name: 'Produkter' }
    },
    { path: '/s/produkter', redirect: { name: 'Produkter' } },
    {
      path: '/:id',
      name: 'Innehall',
      component: () => import('../views/Page.vue')
    }
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
    if (savedPosition) {
      return savedPosition
    }
    if (to.name !== from.name || !_.isEqual(to.params, from.params)) {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 500)
    }
    return {}
  }
})

export default router
