import axios from 'axios'
import { isClient } from '../utils/helpers'

const CancelToken = axios.CancelToken
const cancelKeys = new Map()
const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 25000,
  withCredentials: true
})
if (!isClient) {
  // client.defaults.headers.common['Cache-Control'] = 'no-cache'
  //client.defaults.headers.common['Pragma'] = 'no-cache'
  //client.defaults.headers.common['Expires'] = '0'
  client.interceptors.request.use((config) => {
    config.params = config.params || {}
    config.params['_ssr'] = 'yes'
    return config
  })
}

const fetchSiteGlobals = async () => {
  return client.get('/wp-json/feather/v1/site-globals').then((resp) => {
    return resp.data
  })
}

const fetchPage = async (id: string) => {
  return client.get('/wp-json/wp/v2/pages', { params: { slug: id } }).then((resp) => {
    return {
      id,
      page: resp.data.length > 0 ? resp.data[0] : false
    }
  })
}

const fetchPost = async (id: string) => {
  return client.get('/wp-json/wp/v2/posts', { params: { slug: id } }).then((resp) => {
    return {
      id,
      post: resp.data.length > 0 ? resp.data[0] : false
    }
  })
}

const fetchCategory = async (id: string) => {
  return client
    .get('/wp-json/feather/v1/product_cat', { params: { slug: id } })
    .then((resp) => {
      return { id, category: resp.data }
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return { id, category: false }
      }
      throw err
    })
}

const fetchProduct = async (id: string) => {
  return client
    .get('/wp-json/feather/v1/product', { params: { slug: id } })
    .then((resp) => {
      return { id, product: resp.data }
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return { id, product: false }
      }
      throw err
    })
}

const fetchRelatedProducts = async (id: string) => {
  return client
    .get('/wp-json/feather/v1/related_products', { params: { slug: id } })
    .then((resp) => {
      return { id, products: resp.data }
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return { id, products: false }
      }
      throw err
    })
}

const fetchProducts = (query: any, cancelKey: string | null = null, light: boolean = true) => {
  const qOpts: any = {
    params: { query: JSON.stringify(query) }
  }
  if (light) {
    qOpts.params.light = 1
  }
  if (cancelKey !== null) {
    const cancelObj = cancelKeys.get(cancelKey)
    if (cancelObj) {
      cancelObj.cancel('newer request was started')
    }
    const source = CancelToken.source()
    cancelKeys.set(cancelKey, source)
    qOpts.cancelToken = source.token
  }
  return client.get('/wp-json/feather/v1/products', qOpts).then((resp) => {
    if (cancelKey !== null) {
      cancelKeys.delete(cancelKey)
    }
    return { result: resp.data }
  })
}

const fetchBrands = async (query: any) => {
  return client
    .get('/wp-json/feather/v1/brands', {
      params: { query: JSON.stringify(query) }
    })
    .then((resp) => {
      return { result: resp.data }
    })
}

const fetchTipsAndAdviceCategory = async (slug: string) => {
  return client
    .get('/wp-json/feather/v1/tips-and-advice-category', {
      params: { slug }
    })
    .then((resp) => {
      return { slug: slug, result: resp.data }
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return { slug, result: false }
      }
      throw err
    })
}

const fetchSingleTipsAndAdvice = async (slug: string) => {
  return client
    .get('/wp-json/feather/v1/single-tips-and-advice', { params: { slug } })
    .then((resp) => {
      return { slug, result: resp.data }
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return { slug, result: false }
      }
      throw err
    })
}

const fetchTipsAndAdvice = async () => {
  return client.get('/wp-json/feather/v1/tips-and-advice').then((resp) => {
    return { result: resp.data }
  })
}

const fetchCampaign = async (slug: string) => {
  return client
    .get('/wp-json/feather/v1/campaign', {
      params: { slug }
    })
    .then((resp) => {
      return { result: resp.data }
    })
    .catch((error) => {
      return error
    })
}

const fetchCampaigns = async () => {
  return client.get('/wp-json/feather/v1/campaigns').then((resp) => {
    return { result: resp.data }
  })
}

const fetchHero = async () => {
  return client.get('/wp-json/feather/v1/hero').then((resp) => {
    return { result: resp.data }
  })
}

const addToCart = async (items: any) => {
  return client.post('/wp-json/feather/v1/add-to-cart', { items: items }).then((resp) => {
    return { cart: resp.data }
  })
}

const updateCartItems = async (items: any) => {
  return client.post('/wp-json/feather/v1/update-cart-items', { items: items }).then((resp) => {
    return { cart: resp.data }
  })
}

const fetchCart = async () => {
  return client.get('/wp-json/feather/v1/cart').then((resp) => {
    return { cart: resp.data }
  })
}

const fetchNotices = async () => {
  return client.get('/wp-json/feather/v1/notices').then((resp) => {
    return { notices: resp.data }
  })
}

const fetchMenu = async (slug: string) => {
  return client
    .get('/wp-json/feather/v1/menu', { params: { slug } })
    .then((resp) => {
      return { slug, menuItems: resp.data }
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return { slug, menu: false }
      }
      throw err
    })
}

const fetchSearch = async (search: string, query: any) => {
  return client
    .get('/wp-json/feather/v1/search', {
      params: {
        q: search,
        query: JSON.stringify(query)
      }
    })
    .then((resp) => {
      return { result: resp.data }
    })
}

const fetchMap = async () => {
  return client.get('/wp-json/feather/v1/map').then((resp) => {
    return {
      map: Object.keys(resp.data).length > 0 ? resp.data : {}
    }
  })
}

const submitForm = async (formType: string, formData: any) => {
  return client
    .post('/wp-json/svf/v1/form-submit?type=' + formType, formData)
    .then((resp) => {
      console.log(resp)
      return resp.data
    })
    .catch((err) => {
      if (err.response && err.response.data && err.response.data.message) {
        throw new Error(err.response.data.message)
      }
      throw err
    })
}

export default {
  fetchSiteGlobals,
  fetchPage,
  fetchPost,
  fetchCategory,
  fetchProduct,
  fetchRelatedProducts,
  fetchProducts,
  fetchBrands,
  fetchTipsAndAdviceCategory,
  fetchSingleTipsAndAdvice,
  fetchTipsAndAdvice,
  fetchCampaign,
  fetchCampaigns,
  fetchHero,
  addToCart,
  updateCartItems,
  fetchCart,
  fetchNotices,
  fetchMenu,
  fetchSearch,
  fetchMap,
  submitForm
}
