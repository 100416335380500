<script lang="ts">
import { defineComponent } from 'vue'
import Icon from './Icon.vue'

export default defineComponent({
  name: 'HeaderSearch',
  components: {
    Icon
  },
  data() {
    return {
      search: this.$route.query.q ? this.$route.query.q : null
    }
  },
  methods: {
    submit() {
      this.$router.push({ name: 'Search', query: { q: this.search } })
    }
  }
})
</script>

<template>
  <div>
    <form method="get" action="/search/" @submit.prevent="submit()">
      <label for="search">Sök efter:</label>
      <input
        id="search"
        v-model="search"
        role="search"
        type="text"
        name="q"
        placeholder="Sök produkt, varumärke, kategori..."
      />
      <button type="submit">
        <span>Sök</span>
        <Icon name="search" type="fas" />
      </button>
    </form>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
form {
  position: relative;
  display: flex;
  @media (max-width: 1024px) {
    display: none;
  }
  label {
    // sr-only
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  input {
    width: 343px;
    height: 42px;
    border: 1px solid $highlight;
    border-radius: 2px;
    color: $black;
    font-family: $inter;
    font-weight: 300;
    font-size: 0.8125rem;
    padding: 0.5rem 2.875rem 0.5rem 0.9375rem;
    &::placeholder {
      color: $black;
      font-family: $inter;
      font-weight: 300;
      letter-spacing: -0.17px;
    }
    &:focus {
      outline-color: $main;
      border-color: $main;
    }
  }
  button[type='submit'] {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    border: none;
    background: none;
    padding: 0 1.125rem 0 0.75rem;
    color: black;
    span {
      // sr-only
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
  }
}
</style>
