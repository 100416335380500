<script lang="ts">
import { defineComponent } from 'vue'
import _ from 'lodash'
import Icon from './Icon.vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'

export default defineComponent({
  name: 'CartComponent',
  components: {
    Icon
  },
  setup() {
    const store = useStore()
    const { fetchCart, updateCartItems, setShowCart } = useStore()
    const { cart, showCart } = storeToRefs(store)
    return { fetchCart, updateCartItems, setShowCart, cart, showCart }
  },
  data() {
    return {
      loading: true,
      amount: 0,
      editCartProducts: [] as any[],
      pushUpdates: _.debounce(() => {
        this.pushUpdatesNow()
      }, 1000),
      display: null as any
    }
  },
  computed: {
    total() {
      return this.cart ? this.cart.total : null
    },
    subtotal() {
      return this.cart ? this.cart.subtotal : null
    },
    cartProducts() {
      if (this.cart === null) {
        return []
      }
      return this.cart?.items
        ?.filter((item: any) => !!item.product)
        .map((item: any) => {
          item.variantion = item.variantion || item.product
          return item
        })
    },
    qtyCartProducts() {
      if (this.cart === null) {
        return []
      }
      return this.cart.items
        .filter((item: any) => !!item.product)
        .map((item: any) => {
          item.variantion = item.variantion || item.product
          return item.quantity
        })
    },
    sumOfCartProducts() {
      const sum = this.qtyCartProducts.reduce((a: any, b: any) => {
        return a + b
      }, 0)
      return sum
    },
    cartWeight() {
      return this.cart ? this.cart.weight : null
    }
  },
  watch: {
    showCart: function () {
      this.display = this.showCart
    },
    cartProducts: {
      handler() {
        this.editCartProducts = _.cloneDeep(this.cartProducts)
      },
      immediate: true
    },
    editCartProducts: {
      handler() {
        if (_.isEqual(this.editCartProducts, this.cartProducts)) {
          return
        }
        this.pushUpdates()
      },
      deep: true
    }
  },
  mounted() {
    this.fetchCart().finally(() => {
      this.loading = false
    })
  },
  methods: {
    setQuantity(key: any, quantity: any) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.updateCartItems([{ key, quantity }]).finally(() => {
        this.loading = false
      })
    },
    pushUpdatesNow() {
      this.loading = true
      let changedItems: any[] = []
      this.editCartProducts.forEach((editItem: any) => {
        let item = this.cartProducts.find((p: any) => p.key === editItem.key)
        if (!_.isEqual(item, editItem)) {
          changedItems.push({ key: editItem.key, quantity: editItem.quantity })
        }
      })
      this.updateCartItems(changedItems).finally(() => {
        this.loading = false
      })
    }
  }
})
</script>

<template>
  <div v-if="cart !== null">
    <div v-if="showCart" class="overlay" @click.prevent="() => setShowCart(false)"></div>
    <div v-if="showCart" class="cart">
      <router-link v-if="cartWeight < 600" :to="{ name: 'Checkout' }" class="cart-top-bar">
        <span>Vidare till kassan -></span>
      </router-link>
      <div class="cart-header yellow-top-bar-visible" @click.prevent="() => setShowCart(false)">
        <button class="close" title="Stäng">
          <Icon name="times" type="fal" />
        </button>
        <div class="wrap">
          <div class="bag-wrap">
            <Icon name="shoppingBag" type="far" />
            <span v-if="cartProducts.length > 0" class="product-count">
              {{ sumOfCartProducts }}
            </span>
            <span v-if="cartProducts.length === 0" class="product-count">0</span>
          </div>
        </div>
      </div>
      <div class="cart-main">
        <div v-if="editCartProducts.length === 0" class="empty-cart">
          <p>Inga produkter i varukorgen...</p>
        </div>
        <div v-for="cartProduct in editCartProducts" :key="cartProduct.key" class="container">
          <div class="wrapper">
            <router-link
              :to="{
                name: 'Produkt',
                params: { id: cartProduct.product.slug }
              }"
            >
              <img
                width="60"
                :src="
                  cartProduct.product.featured_image.sizes &&
                  cartProduct.product.featured_image.sizes.medium
                    ? cartProduct.product.featured_image.sizes.medium
                    : cartProduct.product.featured_image.url
                "
                alt=""
                loading="lazy"
                @click="display = !display"
              />
            </router-link>
            <div class="wrapper-details">
              <div class="wrap" @click="display = !display">
                <router-link
                  :to="{
                    name: 'Produkt',
                    params: { id: cartProduct.product.slug }
                  }"
                  class="name"
                >
                  {{ cartProduct.product.name }}
                </router-link>
                <span v-if="cartProduct.variantion.attributes" class="size">
                  {{ cartProduct.variantion.attributes.attribute_pa_size }}
                </span>
              </div>
              <div class="add-rm">
                <button @click="cartProduct.quantity -= 1">
                  <Icon name="minus" type="far" />
                </button>
                <span class="amount">{{ cartProduct.quantity }}</span>
                <button @click="cartProduct.quantity += 1">
                  <Icon name="plus" type="far" />
                </button>
              </div>
            </div>
          </div>
          <div class="wrapper-price">
            <span
              class="price"
              :style="{
                color: cartProduct.variantion.price.on_sale ? '#BA2C34' : 'black'
              }"
            >
              {{
                new Intl.NumberFormat('sv-SE', {
                  maximumFractionDigits: 0
                }).format(cartProduct.variantion.price.price)
              }}:-
            </span>
            <span v-if="cartProduct.variantion.price.on_sale" class="originalPrice">
              {{
                new Intl.NumberFormat('sv-SE', {
                  maximumFractionDigits: 0
                }).format(cartProduct.variantion.price.regular_price)
              }}:-
            </span>
          </div>
        </div>
      </div>
      <div class="cart-footer">
        <router-link v-if="cartWeight < 600" :to="{ name: 'Checkout' }" class="total-cart">
          <div class="total-cart__text">Vidare till kassan</div>
          <div class="wrap">
            <p class="total-price">
              {{
                new Intl.NumberFormat('sv-SE', {
                  maximumFractionDigits: 0
                }).format(subtotal)
              }}:-
            </p>
            <p class="fine-print">Inklusive moms</p>
          </div>
        </router-link>
        <div v-else class="weight-warning">
          För större volymer välkommen att
          <a href="https://www.svenskafoder.se/kontakt/" target="_blank">kontakta</a>
          våra säljare.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.cart {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10002;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  max-width: 483px;
  box-shadow: 0 2px 61px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  animation: 0.5s slideOut ease forwards;
  &-top-bar {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $accent;
    padding: 0.25rem 0;
    color: black;
    span {
      font-family: $inter;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 1.5rem;
      margin-bottom: 0;
      margin-top: 0;
    }
    svg {
      width: 0.75rem;
      height: 0.75rem;
      margin-left: 0.5rem;
    }
  }
  &-header {
    flex-shrink: 0;
    cursor: pointer;
    width: 100%;
    padding: 0 2rem 0 2rem;
    height: 89px;
    border-bottom: 1px solid $highlight;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      padding: 0 1rem 0 1rem;
      height: 73px;
    }
    .wrap {
      display: flex;
      align-items: center;
      @media (min-width: 1025px) {
        margin-right: 0.45rem;
      }
    }
  }
  &-main {
    width: 100%;
    flex: 1 1 auto;
    overflow: auto;
    .empty-cart {
      margin: 2rem;
      @media (max-width: 1024px) {
        margin: 2rem 1rem;
      }
      p {
        font-weight: 700;
        font-size: 0.813em;
      }
    }
    .container {
      margin: 0 2em;
      padding: 2em 0.3em;
      border-bottom: 1px solid $highlight;
      display: flex;
      justify-content: space-between;

      @media (max-width: 1024px) {
        margin: 0 1rem;
      }

      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 58px;
      }

      .wrapper {
        display: flex;
        align-items: center;
        img {
          display: block;
          margin-right: 0.5rem;
          height: 60px;
          width: 60px;
          object-fit: contain;
        }
        &-details {
          display: flex;
          flex-flow: column wrap;
          margin: 0.5em;
          .name {
            font-size: 0.813em;
            font-family: $brandon;
            font-weight: 700;
            letter-spacing: -0.17px;
            text-transform: uppercase;
            margin-right: 0.5em;
            max-width: 200px;
            color: $black;
          }
          .size {
            font-family: $inter;
            font-weight: 300;
            font-size: 0.813em;
            font-weight: 300;
            letter-spacing: -0.17px;
          }
          .add-rm {
            margin-top: 0.5em;
            width: 95px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #e9e9e9;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.5em;
            button {
              background: transparent;
              border: none;
              font-weight: 700;
              display: flex;
              padding: 0.4em 0.2em;
              svg {
                width: 10px;
                height: 10px;
              }
            }
            .amount {
              font-family: $inter;
              font-size: 0.813em;
              font-weight: 300;
              letter-spacing: -0.17px;
            }
          }
        }
      }
      .wrapper-price {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        .price {
          font-family: $brandon;
          font-weight: 900;
          font-size: 1.688em;
          letter-spacing: -0.27px;
          line-height: 35px;
        }
        .originalPrice {
          font-family: $brandon;
          font-weight: 900;
          font-size: 10px;
          letter-spacing: -0.13px;
          line-height: 19px;
          text-align: right;
          text-decoration: line-through;
          margin-top: -0.5em;
          position: relative;
          right: 0.5em;
        }
      }
    }
  }
  &-footer {
    width: 100%;
    position: relative;
    padding: 0 1.125rem 1.125rem 1.125rem;
    background: white;
    @media (max-width: 1024px) {
      padding: 0 1rem 1rem 1rem;
    }
    &::before {
      content: '';
      pointer-events: none;
      position: absolute;
      top: -90px;
      height: 90px;
      width: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 60%);
    }
    .total-cart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $main;
      color: $white;
      padding: 1.5rem 2rem;
      @media (max-width: 1024px) {
        padding: 1.5rem 1rem;
      }
      &:hover {
        .total-cart__text::before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
      &__text {
        color: $white !important;
        font-family: $inter;
        font-weight: 600;
        font-size: 0.938em;
        font-weight: 600;
        letter-spacing: -0.2px;
        position: relative;
        //hover underline effect
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -5px;
          left: 0;
          background-color: $white;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out;
        }
      }
      .wrap {
        p {
          padding: 0;
          margin: 0;
          text-align: right;
        }
        .total-price {
          font-family: $brandon;
          font-weight: 900;
          font-size: 1.313em;
          letter-spacing: -0.28px;
        }
        .fine-print {
          font-family: $inter;
          font-weight: 400;
          font-size: 0.625em;
          letter-spacing: -0.31px;
        }
      }
    }
    .weight-warning {
      font-family: 'Inter', sans-serif;
      background-color: rgba(255, 213, 0, 0.75);
      padding: 3em;
      border-radius: 5px;
    }
  }
}
.bag-wrap {
  position: relative;
  left: -1px;
  @media (max-width: 1024px) {
    margin-right: 0.4rem;
  }
  svg {
    font-size: 2.2rem;
    position: relative;
    padding-left: 8px;
  }
  .product-count {
    position: absolute;
    color: $white;
    font-size: 0.75rem;
    line-height: 20px;
    top: 0.3em;
    right: -0.6em;
    border-radius: 333px;
    background: $main;
    height: 19px;
    width: 19px;
    text-align: center;
    font-family: $brandon;
    font-weight: 700;
  }
}
</style>
