import { defineStore } from 'pinia'
import apiClient from '../api/api'

/*
          col: 4,
          row: 5,
          span: 1,

          col: 3,
          row: 10,
          span: 2,

          col: 1,
          row: 15,
          span: 2,

          col: 1,
          row: 20,
          span: 1,
*/

const processTipsAndRadPositioning = (tips_and_advice: any[]) => {
  return (tips_and_advice || []).map((tip: any, index: number) => {
    tip.span = 2
    if (index % 2 == 1) {
      tip.col = 1
    } else {
      tip.col = 5 - tip.span
    }
    tip.row = Math.ceil(5 * (index + 1))
    return tip
  })
}

export interface StoreState {
  siteGlobals: any
  showCart: boolean
  showMobileMenu: boolean
  loadingState: boolean
  showFilter: boolean
  categories: any
  products: any
  brands: any
  relatedProducts: any
  pages: any
  posts: any
  menus: any
  map: any
  hero: any
  notices: any
  productsQueryResult: any
  productsQuery: any
  searchQueryResult: any
  searchQuery: any
  tipsAndAdvice: any
  tipsAndAdviceCategory: any
  tipsAndAdviceQueryResult: any
  campaign: any
  campaigns: any
  cart: any
}

export const useStore = defineStore('store', {
  state(): StoreState {
    return {
      siteGlobals: null,
      showCart: false,
      showMobileMenu: false,
      loadingState: false,
      showFilter: true,
      categories: {},
      products: {},
      brands: null,
      relatedProducts: {},
      pages: {},
      posts: {},
      menus: {},
      map: {},
      hero: {},
      notices: [],
      productsQueryResult: {},
      productsQuery: {},
      searchQueryResult: {},
      searchQuery: {},
      tipsAndAdvice: {},
      tipsAndAdviceCategory: {},
      tipsAndAdviceQueryResult: null,
      campaign: null,
      campaigns: null,
      cart: null
    }
  },
  getters: {
    getShowCart: state => state.showCart,
    getShowMobileMenu: state => state.showMobileMenu,
    getLoadingState: state => state.loadingState,
    getShowFilter: state => state.showFilter
  },
  actions: {
    setSiteGlobals(globals: any) {
      this.siteGlobals = globals
    },
    setCategory({ id, category }: { id: string; category: any }) {
      this.categories[id] = category
    },
    setProduct({ id, product }: { id: string; product: any }) {
      this.products[id] = product
    },
    setRelatedProducts({ id, products }: { id: string; products: any }) {
      this.relatedProducts[id] = products
    },
    setPage({ id, page }: { id: string; page: any }) {
      this.pages[id] = page
    },
    setPost({ id, post }: { id: string; post: any }) {
      this.posts[id] = post
    },
    setProductsQueryResult({ id, result }: { id: string; result: any }) {
      result.tips_and_advice = processTipsAndRadPositioning(
        result.tips_and_advice
      )
      this.productsQueryResult[id] = result
    },
    addAdditionalProductsToResult({ id, result }: { id: string; result: any }) {
      this.productsQueryResult[id].hits = this.productsQueryResult[
        id
      ].hits.concat(result.hits)
      this.productsQueryResult[id].tips_and_advice =
        processTipsAndRadPositioning(
          this.productsQueryResult[id].tips_and_advice.concat(
            result.tips_and_advice
          )
        )
    },
    setProductsQuery({ id, query }: { id: string; query: any }) {
      this.productsQuery[id] = query
    },
    setCart({ cart }: { cart: any }) {
      this.cart = cart
    },
    setNotices({ notices }: { notices: any }) {
      this.notices = notices
    },
    setMenu({ slug, menuItems }: { slug: string; menuItems: any }) {
      this.menus[slug] = menuItems
    },
    setMap({ map }: { map: any }) {
      this.map = map
    },
    setHero({ result }: { result: any }) {
      this.hero = result
    },
    setSearchQueryResult({ id, result }: { id: string; result: any }) {
      this.searchQueryResult[id] = result
    },
    addAdditionalSearchToResult({ id, result }: { id: string; result: any }) {
      this.searchQueryResult[id].hits = this.searchQueryResult[id].hits.concat(
        result.hits
      )
    },
    setSearchQuery({ id, query }: { id: string; query: any }) {
      this.searchQuery[id] = query
    },
    setBrands({ result }: { result: any }) {
      this.brands = result
    },
    setTipsAndAdvice({ slug, result }: { slug: string; result: any }) {
      this.tipsAndAdvice[slug] = result
    },
    setTipsAndAdviceCategory({ slug, result }: { slug: string; result: any }) {
      this.tipsAndAdviceCategory[slug] = result
    },
    setTipsAndAdviceQueryResult({ result }: { result: any }) {
      this.tipsAndAdviceQueryResult = result
    },
    setCampaign({ result }: { result: any }) {
      this.campaign = result
    },
    setCampaigns({ result }: { result: any }) {
      this.campaigns = result
    },
    setShowCart(newState: any) {
      if (newState !== undefined) {
        this.showCart = newState
      } else {
        this.showCart = !this.showCart
      }
    },
    setShowMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },
    setLoadingState(newState: boolean) {
      this.loadingState = newState
    },
    setShowFilter(newState: any) {
      if (newState !== undefined) {
        this.showFilter = newState
      } else {
        this.showFilter = !this.showFilter
      }
    },
    async fetchSiteGlobals() {
      return apiClient.fetchSiteGlobals().then(resp => {
        this.setSiteGlobals(resp)
        return resp
      })
    },
    async fetchPage(id: string) {
      return apiClient.fetchPage(id).then(resp => {
        this.setPage(resp)
        return resp
      })
    },
    async fetchPost(id: string) {
      return apiClient.fetchPost(id).then(resp => {
        this.setPost(resp)
        return resp
      })
    },
    async fetchCategory(id: string) {
      return apiClient.fetchCategory(id).then(resp => {
        this.setCategory(resp)
        return resp
      })
    },
    async fetchProduct(id: string) {
      return apiClient.fetchProduct(id).then(resp => {
        this.setProduct(resp)
        return resp
      })
    },
    async fetchRelatedProducts(id: string) {
      return apiClient.fetchRelatedProducts(id).then(resp => {
        this.setRelatedProducts(resp)
        return resp
      })
    },
    async fetchProducts({ id, query }: { id: string; query: any }) {
      this.setLoadingState(true)
      this.setProductsQuery({ id, query })
      return apiClient
        .fetchProducts(query, 'vuex-products')
        .then(resp => {
          this.setProductsQueryResult({ id, result: resp.result })
          return resp
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },
    async fetchMoreProducts({
      queryId,
      count = 1
    }: {
      queryId: string
      count: number
    }) {
      const newQuery = { ...this.productsQuery[queryId] }
      for (let i = 1; i <= count; i++) {
        newQuery.offset += newQuery.limit
        console.log('Fetching additional products')
        await apiClient
          .fetchProducts(newQuery, 'vuex-products-more-' + newQuery.offset)
          .then(resp => {
            this.addAdditionalProductsToResult({
              id: queryId,
              result: resp.result
            })
            return resp
          })
      }
      this.setProductsQuery({ id: queryId, query: newQuery })
      this.setLoadingState(false)
      return Promise.resolve()
    },
    async addToCart(items: any) {
      return apiClient
        .addToCart(items)
        .then(resp => {
          this.setCart(resp)
          return resp
        })
        .finally(() => {
          return this.fetchNotices()
        })
    },
    async updateCartItems(items: any) {
      return apiClient
        .updateCartItems(items)
        .then(resp => {
          this.setCart(resp)
          return resp
        })
        .finally(() => {
          return this.fetchNotices()
        })
    },
    async fetchBrands() {
      return apiClient.fetchBrands('{}').then(resp => {
        this.setBrands(resp)
        return resp
      })
    },
    async fetchTipsAndAdvice() {
      return apiClient.fetchTipsAndAdvice().then(resp => {
        this.setTipsAndAdviceQueryResult(resp)
        return resp
      })
    },
    async fetchSingleTipsAndAdvice(id: string) {
      return apiClient.fetchSingleTipsAndAdvice(id).then(resp => {
        this.setTipsAndAdvice(resp)
        return resp
      })
    },
    async fetchTipsAndAdviceCategory(slug: string) {
      return apiClient.fetchTipsAndAdviceCategory(slug).then(resp => {
        this.setTipsAndAdviceCategory(resp)
        return resp
      })
    },
    async fetchCampaign(slug: string) {
      return apiClient.fetchCampaign(slug).then(resp => {
        this.setCampaign(resp)
        return resp
      })
    },
    async fetchCampaigns() {
      return apiClient.fetchCampaigns().then(resp => {
        this.setCampaigns(resp)
        return resp
      })
    },
    async fetchCart() {
      return apiClient.fetchCart().then(resp => {
        this.setCart(resp)
        return resp
      })
    },
    async fetchNotices() {
      return apiClient.fetchNotices().then(resp => {
        this.setNotices(resp)
        return resp
      })
    },
    async fetchMenu(slug: string) {
      return apiClient.fetchMenu(slug).then(resp => {
        this.setMenu(resp as any)
        return resp
      })
    },
    async fetchMap() {
      return apiClient.fetchMap().then(resp => {
        this.setMap(resp)
        return resp
      })
    },
    async fetchHero() {
      return apiClient.fetchHero().then(resp => {
        this.setHero(resp)
        return resp
      })
    },
    async fetchSearch({ id, query }: { id: string; query: any }) {
      this.setLoadingState(true)
      this.setSearchQuery({ id, query })
      return apiClient
        .fetchSearch(id, query)
        .then(resp => {
          this.setSearchQueryResult({ id, result: resp.result })
          return resp
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },
    async fetchMoreSearch(queryId: string) {
      const newQuery = { ...this.productsQuery[queryId] }
      newQuery.offset += newQuery.limit
      this.setSearchQuery({ id: queryId, query: newQuery })
      return apiClient
        .fetchSearch(queryId, newQuery)
        .then(resp => {
          this.addAdditionalSearchToResult({
            id: queryId,
            result: resp.result
          })
          return resp
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    }
  }
})
