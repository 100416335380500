<script lang="ts">
import { defineComponent } from 'vue'
import HeaderComponent from './components/Header.vue'
import FooterComponent from './components/Footer.vue'
import Cart from './components/Cart.vue'
import Notices from './components/Notices.vue'
import MobileMenu from './components/MobileMenu.vue'
import { storeToRefs } from 'pinia'
import { useStore } from './store'
import { isClient } from './utils/helpers'

export default defineComponent({
  components: { HeaderComponent, FooterComponent, Cart, Notices, MobileMenu },
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        property: 'og:site_name',
        content: 'Djur&Natur',
        vmid: 'og:site_name'
      },
      {
        property: 'og:type',
        content: 'website',
        vmid: 'og:type'
      },
      {
        property: 'og:image',
        content: '/img/og-image.jpg',
        vmid: 'og:image'
      }
    ],
    link: [
      { rel: 'favicon', href: '/favicon.ico' },
      {
        rel: 'sitemap',
        type: 'application/xml',
        href: import.meta.env.VITE_API_URL + '/sitemap.xml'
      }
    ],
    title: 'Djur&Natur',
    titleTemplate: '%s | Djur&Natur'
  },
  setup() {
    const store = useStore()
    const { setLoadingState, setShowCart, fetchNotices, fetchSiteGlobals } =
      useStore()
    const { showCart, showMobileMenu } = storeToRefs(store)
    return {
      setLoadingState,
      setShowCart,
      fetchNotices,
      fetchSiteGlobals,
      showCart,
      showMobileMenu
    }
  },
  watch: {
    $route() {
      this.setLoadingState(true)
      this.setShowCart(false)
    },
    showCart() {
      if (this.showCart === true) {
        document.documentElement.classList.add('no-scroll')
      } else {
        document.documentElement.classList.remove('no-scroll')
      }
    },
    showMobileMenu() {
      if (this.showMobileMenu === true) {
        document.documentElement.classList.add('no-scroll')
      } else {
        document.documentElement.classList.remove('no-scroll')
      }
    }
  },
  mounted() {
    this.fetchNotices()

    if (isClient && !useStore()?.siteGlobals) {
      this.fetchSiteGlobals()
    }
  },
  serverPrefetch() {
    return this.fetchSiteGlobals()
  }
})
</script>

<template>
  <div>
    <Cart />
    <MobileMenu />
    <HeaderComponent class="topbar-shown" />
    <Notices />
    <!-- <transition name="fade" mode="out-in"> -->
    <main>
      <router-view
        :key="'a' + $route.params.id"
        class="view view-topbar-shown"
      ></router-view>
    </main>
    <!-- </transition> -->
    <FooterComponent />
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/style.scss';

* {
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.mapbox-popups-popup {
  font-family: $inter;
  font-weight: 300;
  .popup-company-name {
    text-transform: uppercase;
    font-family: $brandon;
    font-weight: 700;
    margin-bottom: 0rem;
  }
  .popup-city {
    text-transform: uppercase;
    font-family: $brandon;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: $main;
    font-size: 0.75rem;
  }
  .popup-phone {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
  }
  .popup-link a {
    font-family: $inter;
    font-weight: 400;
    font-size: 0.75rem;
    color: #444;
    position: relative;
    //hover underline effect
    &:focus {
      outline: none;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -5px;
      left: 0;
      background-color: $main;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out;
    }
    &:hover::before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

#app {
  color: $black;
  font-family: $inter;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  a,
  button,
  label {
    text-decoration: none;
    cursor: pointer;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.62);
  }

  /* Hamburger Button */
  .header__burger {
    position: absolute;
    top: -3.6em;
    right: 0.6em;
    z-index: 20004;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    .burger__inner {
      position: relative;
      width: 20px;
      height: 4px;
      border-radius: 10px;
      background-color: $black;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.25s ease-out;

      &:before,
      &:after {
        content: '';
        position: absolute;
        z-index: 20007;
        width: 100%;
        height: 4px;
        border-radius: 10px;
        background-color: inherit;
        transition: all 0.25s ease-out;
      }

      &:after {
        top: 12px;
      }
    }
  }

  // menu/cart close btn
  .close {
    background: transparent;
    border: none;
    font-size: 1rem;
    padding: 0;
    margin-top: 0.5rem;
  }

  .view {
    padding-top: 186px;

    @media (min-width: 1025px) {
      padding-top: 193px;
    }
  }
  .view-topbar-hidden {
    padding-top: 152px;

    @media (min-width: 1025px) {
      padding-top: 168px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
