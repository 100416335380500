<script lang="ts">
import { defineComponent } from 'vue'
import HeaderCart from './HeaderCart.vue'
import MenuItem from './MenuItem.vue'
import HeaderSearch from './HeaderSearch.vue'
import MobileHeader from './MobileHeader.vue'
import TopBar from './TopBar.vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'

export default defineComponent({
  name: 'HeaderComponent',
  components: {
    HeaderCart,
    HeaderSearch,
    MobileHeader,
    TopBar,
    MenuItem
  },
  setup() {
    const store = useStore()
    const { fetchMenu } = useStore()
    const { menus } = storeToRefs(store)
    return { fetchMenu, menus }
  },
  data() {
    return {
      showNavbar: true,
      showPartialNavbar: false,
      loadingMainMenu: true,
      lastScrollPosition: 0,
      scrollValue: 0
    }
  },
  computed: {
    navTop() {
      return this.menus?.top ? this.menus.top.items : []
    },
    navMain() {
      return this.menus?.main ? this.menus.main.items : []
    }
  },
  mounted() {
    this.lastScrollPosition = window.scrollY
    window.addEventListener('scroll', this.onScroll)
    if (this.navTop.length == 0 || this.navMain.length == 0) {
      this.fetchMenus()
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      const OFFSET = 190
      const PARTIAL_OFFSET = 190

      if (window.scrollY < 0) {
        return
      }
      if (Math.abs(window.scrollY - this.lastScrollPosition) < OFFSET) {
        return
      }
      if (window.scrollY < PARTIAL_OFFSET) {
        this.showNavbar = window.scrollY < this.lastScrollPosition
        this.showPartialNavbar = false
      } else {
        this.showNavbar = window.scrollY < this.lastScrollPosition
        this.showPartialNavbar = true
      }
      this.lastScrollPosition = window.scrollY
    },
    async fetchMenus() {
      this.loadingMainMenu = true

      return Promise.all([this.fetchMenu('top'), this.fetchMenu('main')]).then(
        () => {
          this.loadingMainMenu = false
        }
      )
    }
  },
  serverPrefetch() {
    return this.fetchMenus()
  }
})
</script>

<template>
  <header
    class="header"
    :class="{
      'hidden-navbar': !showNavbar,
      'partial-navbar': showPartialNavbar
    }"
  >
    <TopBar />
    <nav class="nav-top">
      <div class="left">
        <h1>
          <router-link to="/" title="Djur&amp;Natur">
            <img
              src="/djur&natur.svg"
              width="153"
              height="44"
              alt="Djur&amp;Natur logo"
            />
          </router-link>
        </h1>
        <MenuItem
          v-for="navItem in navTop"
          :key="navItem.id"
          :nav-item="navItem"
        />
        <HeaderSearch />
      </div>
      <div class="right">
        <HeaderCart />
      </div>
    </nav>
    <nav :class="{ 'loading-height': loadingMainMenu }" class="nav-sub">
      <MenuItem
        v-for="navItem in navMain"
        :key="navItem.id"
        :nav-item="navItem"
      />
    </nav>
    <MobileHeader />
  </header>
</template>

<style lang="scss">
@import '@/assets/scss/style.scss';

.header.topbar-shown.partial-navbar {
  transform: translateY(calc(-64px));
}
.header.topbar-shown.hidden-navbar {
  transform: translateY(calc(-64px));
}
.header.hidden-navbar {
  border-bottom: 1px solid $highlight;
  .nav-sub {
    transform: translateY(-42px);
  }
}

.header {
  z-index: 1000;
  width: 100%;
  position: fixed;
  background: white;
  transition: 0.3s all ease-out;

  .nav-top {
    padding: 0 2rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    width: 100%;
    @media (max-width: 1024px) {
      padding: 0 0.875rem;
    }
    .left {
      display: flex;
      align-items: center;
      h1 {
        margin: 1.375rem 0;
        @media (max-width: 1024px) {
          margin: 0.875rem 0;
        }
        a {
          margin-right: 2.75rem;
          height: 44px;
          display: block;
          @media (max-width: 1300px) {
            margin-right: 1.75rem;
          }
          span {
            font-size: 0;
          }
        }
      }
      a {
        color: $black;
        font-size: 0.9375rem;
        font-family: $inter;
        font-weight: 600;
        letter-spacing: -0.2px;
        position: relative;
        margin-right: 2.5rem;
        @media (max-width: 1300px) {
          margin-right: 1.75rem;
        }
        @media (max-width: 1024px) {
          &:not(:first-child) {
            display: none;
          }
        }
        //hover underline effect
        &:not(:first-child)::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -5px;
          left: 0;
          background-color: $main;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out;
        }
        &:not(:first-child):hover::before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
    .right {
      display: flex;
      a {
        color: $black;
      }
    }
  }
  .nav-sub {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: -1;
    display: flex;
    align-items: center;
    align-content: center;
    background: $main;
    transition: 0.3s all ease-out;
    &.loading-height {
      height: 42px;
    }
    @media (max-width: 1024px) {
      display: none;
    }
    a {
      font-size: 0.9375rem;
      line-height: 1.5rem;
      letter-spacing: -0.2px;
      font-family: $gothic;
      font-weight: 700;
      text-transform: uppercase;
      color: $white;
      padding: 9px 2em;

      transition: 0.2s ease-in-out;
      @media (min-width: 1024px) and (max-width: 1210px) {
        padding: 9px 1em;
      }
      &:hover {
        color: $accent;
      }
      &.router-link-active {
        background: #003863;
      }
    }
  }
}
</style>
