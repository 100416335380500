<script lang="ts">
import { defineComponent } from 'vue'
import Icon from './Icon.vue'

export default defineComponent({
  name: 'TopBar',
  components: {
    Icon
  },
  data() {
    return {
      sellingPoints: ['Fria returer', 'Snabba leveranser', 'Öppet köp i 30 dagar']
    }
  }
})
</script>

<template>
  <div class="top-bar">
    <div class="sellingpoints">
      <p
        v-for="(sellingPoint, index) in sellingPoints"
        :key="sellingPoint"
        :class="`sellingpoint sellingpoint-${index + 1}`"
      >
        <Icon name="check" type="far" />
        {{ sellingPoint }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
.top-bar {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background: $accent;
  padding: 0.25rem 0;
  .sellingpoints {
    display: flex;
    justify-content: center;
    width: 95%;
    @media (max-width: 516px) {
      flex-wrap: wrap;
    }
    p {
      font-family: $inter;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 1.5rem;
      margin-bottom: 0;
      margin-top: 0;
      @media (max-width: 1024px) {
        font-size: 0.75rem;
      }
      & + p {
        margin-left: 0.8125rem;
      }
      &.sellingpoint-3 {
        @media (max-width: 499px) {
          display: none;
        }
      }
    }
  }
  &-close {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    button {
      background: transparent;
      border: none;
      svg {
        vertical-align: bottom;
      }
    }
  }
  svg {
    font-size: 0.75em;
    margin: 0 0.25em 0.1em;
  }
}
</style>
