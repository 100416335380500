<script lang="ts">
import { defineComponent } from 'vue'
// if link exists on navItem, it contains a local route, this is controlled by
// function map_menu in backend/web/app/plugins/duva-feather/src/types.php
export default defineComponent({
  name: 'MenuItem',
  props: {
    navItem: {
      type: Object,
      required: true
    }
  }
})
</script>

<template>
  <router-link
    v-if="navItem.link"
    :to="{
      name: navItem.link.route,
      params: navItem.link.params,
      query: navItem.link.query
    }"
  >
    {{ navItem.title }}
  </router-link>
  <router-link v-else-if="navItem.url.indexOf('/') === 0" :to="navItem.url">
    {{ navItem.title }}
  </router-link>
  <a v-else :href="navItem.url">
    {{ navItem.title }}
  </a>
</template>
