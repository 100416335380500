<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'

export default defineComponent({
  name: 'IconComponent',
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    componentName() {
      //return (this.type ? this.type + '_' : '') + this.name
      const n = this.name.charAt(0).toUpperCase() + this.name.slice(1)
      return this.type ? this.type + n : this.name
    },
    iconComponent() {
      return defineAsyncComponent(() => import(`./icons/${this.componentName}.vue`)) // svg?component
    }
  }
})
</script>

<template>
  <component
    :is="iconComponent"
    role="img"
    aria-hidden="true"
    focusable="false"
    fill="currentColor"
    :class="'icon icon-' + name"
  />
</template>

<style lang="scss" scoped>
.icon {
  display: inline-flex;
  width: 1em;
  height: auto;
  vertical-align: -0.125em; // Copied from FontAwesome
}
</style>
