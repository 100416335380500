<script lang="ts">
import { defineComponent } from 'vue'
import Icon from './Icon.vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'

export default defineComponent({
  name: 'HeaderCart',
  components: {
    Icon
  },
  setup() {
    const store = useStore()
    const { updateCartItems, setShowCart } = useStore()
    const { cart } = storeToRefs(store)

    return { cart, setShowCart, updateCartItems }
  },
  data() {
    return {
      loading: false,
      showCart: false
    }
  },
  computed: {
    total() {
      return this.cart ? this.cart.total : null
    },
    subtotal() {
      return this.cart ? this.cart.subtotal : null
    },
    haveCart() {
      return !!this.cart
    },
    cartProducts() {
      if (this.cart === null) {
        return []
      }
      return this.cart?.items
        ?.filter((item: any) => !!item.product)
        .map((item: any) => {
          item.variantion = item.variantion || item.product
          return item.quantity
        })
    },
    sumOfCartProducts() {
      const sum = this.cartProducts.reduce((a: any, b: any) => {
        return a + b
      }, 0)
      return sum
    }
  },
  methods: {
    setQuantity(key: any, quantity: any) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.updateCartItems([{ key, quantity }]).finally(() => {
        this.loading = false
      })
    }
  }
})
</script>

<template>
  <div>
    <button class="openCart" :disabled="!haveCart" @click.prevent="setShowCart">
      <span v-if="cart !== null" class="total" :class="{ fadein: haveCart }">
        {{
          new Intl.NumberFormat('sv-SE', {
            maximumFractionDigits: 0
          }).format(subtotal)
        }}:-
      </span>
      <div class="bag-wrap">
        <Icon name="shoppingBag" type="far" />
        <span v-if="cart !== null" class="product-count" :class="{ fadein: haveCart }">
          {{ cartProducts?.length > 0 ? sumOfCartProducts : '0' }}
        </span>
      </div>
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
.openCart {
  background: transparent;
  border: none;
  color: $black;
  font-family: $brandon;
  font-weight: 900;
  font-size: 1.125em;
  letter-spacing: -0.24px;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  .total {
    padding-top: 0.3em;
    opacity: 0;
    &.fadein {
      transition: opacity 0.3s linear;
      opacity: 1;
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.bag-wrap {
  position: relative;
  @media (max-width: 1024px) {
    margin-right: 2em;
  }
  svg {
    font-size: 1.9rem;
    position: relative;
    padding-left: 8px;
    @media (min-width: 1025px) {
      font-size: 2.2rem;
    }
  }
  .product-count {
    position: absolute;
    color: $white;
    font-size: 0.75rem;
    line-height: 20px;
    top: 0.3em;
    right: -0.6em;
    border-radius: 333px;
    background: $main;
    height: 19px;
    width: 19px;
    text-align: center;
    font-family: $brandon;
    font-weight: 700;
    opacity: 0;
    &.fadein {
      transition: opacity 0.3s linear;
      opacity: 1;
    }
  }
}
</style>
