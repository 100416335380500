<script lang="ts">
import { defineComponent } from 'vue'
import Icon from './Icon.vue'
import MenuItem from './MenuItem.vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'

export default defineComponent({
  name: 'MobileMenu',
  components: {
    Icon,
    MenuItem
  },
  setup() {
    const store = useStore()
    const { setShowMobileMenu } = useStore()
    const { showMobileMenu, menus } = storeToRefs(store)
    return { setShowMobileMenu, showMobileMenu, menus }
  },
  computed: {
    navTop() {
      return this.menus.top ? this.menus.top.items : []
    },
    navMain() {
      return this.menus.main ? this.menus.main.items : []
    }
  }
})
</script>

<template>
  <div>
    <div v-if="showMobileMenu" class="overlay" @click.prevent="setShowMobileMenu"></div>
    <div v-if="showMobileMenu" class="mobile-menu">
      <div class="mobile-menu-header yellow-top-bar-visible" @click.prevent="setShowMobileMenu">
        <button class="close" title="Stäng">
          <Icon name="times" type="fal" />
        </button>
        <div class="wrap">
          <p class="heading">meny</p>
        </div>
      </div>
      <nav class="main" @click.prevent="setShowMobileMenu">
        <div class="nav">
          <div v-for="navItem in navMain" :key="navItem.id" class="menu-item">
            <MenuItem :nav-item="navItem" />
            <Icon name="arrowRight" type="fas" />
          </div>
        </div>
        <div class="nav-sub">
          <MenuItem v-for="navItem in navTop" :key="navItem.id" :nav-item="navItem" />
        </div>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10002;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  max-width: 483px;
  box-shadow: 0 2px 61px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  animation: 0.5s slideOut ease forwards;

  &-header {
    cursor: pointer;
    width: 100%;
    padding: 0 1rem;
    height: 95px;
    border-bottom: 1px solid $highlight;
    display: flex;
    justify-content: space-between;

    &.yellow-top-bar-visible {
      @media (max-width: 1024px) {
        height: 105px; // Temp. until we figure out the header
        padding-top: 30px;
      }
    }

    .heading {
      font-family: $brandon;
      font-weight: 700;
      font-size: 0.938rem;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      padding-top: 0.3rem;
    }
    .wrap {
      display: flex;
      align-items: center;
      @media (min-width: 1025px) {
        margin-right: 0.45rem;
      }
    }
  }
  .main {
    width: 100%;
    flex: 1 1 auto;
    overflow: auto;
    a {
      padding: 1.5rem 1rem;
      width: 100%;
      border-bottom: 1px solid $highlight;
    }

    .nav {
      display: flex;
      flex-flow: column wrap;
      .menu-item {
        position: relative;

        a {
          display: flex;
          justify-content: space-between;
          font-size: 1rem;
          color: $main;
          font-family: $gothic;
          font-weight: 700;
          text-transform: uppercase;
        }
        .icon {
          color: $border;
          position: absolute;
          right: 1rem;
          top: 1.5rem;
          pointer-events: none;
        }
      }
    }
    .nav-sub {
      display: flex;
      flex-flow: column wrap;
      a {
        color: $black;
        font-family: $inter;
        font-weight: 600;
        letter-spacing: -0.2px;
        font-size: 0.9rem;
      }
    }
  }
}
</style>
