<script lang="ts">
import { defineComponent } from 'vue'
import Icon from './Icon.vue'
import MenuItem from './MenuItem.vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'

export default defineComponent({
  name: 'FooterComponent',
  components: {
    Icon,
    MenuItem
  },
  setup() {
    const store = useStore()
    const { fetchMenu } = useStore()
    const { siteGlobals, categories, menus } = storeToRefs(store)
    return {
      fetchMenu,
      siteGlobals,
      categories,
      menus
    }
  },
  computed: {
    footer() {
      let contact = []
      if (this.footerMain && this.footerMain._phone !== '') {
        contact.push({ phone: this.footerMain._phone })
      }
      if (this.footerMain && this.footerMain._open_hours !== '') {
        contact.push({ phoneExtra: this.footerMain._open_hours })
      }
      if (this.footerMain && this.footerMain._postal_adress !== '') {
        contact.push({ address: this.footerMain._postal_adress })
      }
      if (this.footerMain && this.footerMain._email !== '') {
        contact.push({ email: this.footerMain._email })
      }
      return {
        descr: this.footerMain ? this.footerMain.content : '',
        facebook: 'https://sv-se.facebook.com/djuronatur',
        instagram: 'https://www.instagram.com/djuronatur.se/',
        company: this.footerMain ? this.footerMain._company_name : '',
        contact: contact
      }
    },
    footerMain() {
      return this.siteGlobals ? this.siteGlobals.footer_main : {}
    },
    navCategories() {
      return this.menus.categories?.items ? this.menus.categories.items : []
    },
    navService() {
      return this.menus.services?.items ? this.menus.services.items : []
    },
    navBrands() {
      return this.menus.brands?.items ? this.menus.brands.items : []
    }
  },
  mounted() {
    if (
      this.navCategories.length === 0 ||
      this.navBrands.length === 0 ||
      this.navService.length === 0
    ) {
      this.fetchMenus()
    }
  },
  methods: {
    fetchMenus() {
      return Promise.all([
        this.fetchMenu('categories'),
        this.fetchMenu('services'),
        this.fetchMenu('brands')
      ])
    }
  },
  // Server-side only
  serverPrefetch() {
    return this.fetchMenus()
  }
})
</script>

<template>
  <footer>
    <div class="footer">
      <div class="footer-info">
        <h2>
          <router-link to="/" title="Djur&amp;Natur">
            <img
              src="/djur&natur.svg"
              width="153"
              height="44"
              alt="Djur&amp;Natur logo"
            />
          </router-link>
        </h2>
        <div class="footer-info__descr" v-html="footer.descr" />
        <h3>{{ footer.company }}</h3>
        <p
          v-for="(row, index) in footer.contact"
          :key="index"
          class="footer-info__contact"
        >
          <a
            v-if="row.phone"
            :aria-label="`Ring ${row.phone}`"
            :href="'tel:' + row.phone"
          >
            {{ row.phone }}
          </a>
          <span v-if="row.phoneExtra" class="phone-extra-info">
            {{ row.phoneExtra }}
          </span>
          <span v-if="row.address">{{ row.address }}</span>
          <a
            v-if="row.email"
            :aria-label="`Maila ${row.email}`"
            :href="'mailto:' + row.email"
          >
            {{ row.email }}
          </a>
        </p>
        <div>
          <Icon name="klarna" />
        </div>
        <!-- <div class="footer-info__social">
          <a :href="footer.facebook" title="Facebook">
            <Icon name="facebookSquare" type="fab" />
          </a>
          <a :href="footer.instagram" title="Instagram">
            <Icon name="instagramSquare" type="fab" />
          </a>
        </div> -->
      </div>
      <nav class="footer-nav">
        <h3>Vårt sortiment</h3>
        <div v-for="(itemCat, index) in navCategories" :key="index">
          <MenuItem :nav-item="itemCat" />
        </div>
      </nav>
      <nav class="footer-nav">
        <h3>Kundservice</h3>
        <div v-for="(itemService, index) in navService" :key="index">
          <MenuItem :nav-item="itemService" />
        </div>
      </nav>
      <nav class="footer-nav">
        <h3>Varumärken</h3>
        <div class="brand-wrap">
          <div
            v-for="(itemBrand, index) in navBrands"
            :key="index"
            class="brand"
          >
            <MenuItem :nav-item="itemBrand" />
          </div>
        </div>
        <div class="brand-all">
          <div>
            <router-link :to="{ name: 'Brands' }">Visa alla</router-link>
          </div>
        </div>
      </nav>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.footer {
  margin: 8rem 0;
  padding: 2rem;
  font-family: $inter;
  font-weight: 400;
  font-size: 0.875rem;
  display: grid;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 3rem 1rem 2rem;
    margin: 0;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1439px) {
    grid-template-columns: 1fr max-content max-content max-content;
    gap: 11rem;
  }
  &-info {
    line-height: 1.8;
    margin-bottom: 1.5em;
    @media (min-width: 768px) and (max-width: 1439px) {
      grid-column: span 3;
    }
    h2 {
      margin-top: 0;
    }
    h3 {
      font-family: $brandon;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 0;
      line-height: 1.5;
    }
    &__descr {
      max-width: 500px;
      @media (min-width: 1439px) {
        min-width: 350px;
      }
    }
    &__contact {
      margin: 0;
      .phone-extra-info {
        color: $dark;
        display: block;
        font-size: 0.75rem;
      }
      a {
        color: $black;
        position: relative;
        //hover underline effect
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -5px;
          left: 0;
          background-color: $main;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out;
        }
        &:hover::before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
    &__social {
      margin: 1em 0;
      svg {
        font-size: 1.8em;
        color: $main;
        margin-right: 0.2em;
      }
    }
  }
  &-nav {
    margin-right: 1rem;
    margin-bottom: 1rem;
    h3 {
      font-size: 0.8125rem;
      font-family: $brandon;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      margin-top: 0;
    }
    div {
      margin: 1em 0;
      a {
        color: $black;
        position: relative;
        //hover underline effect
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -5px;
          left: 0;
          background-color: $main;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out;
        }
        &:hover::before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
    .brand-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 4rem;
      margin: 0;
      .brand {
        margin: 0 1em 1em 0;
      }
    }
    .brand-all {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 4rem;
      div {
        grid-column: 2;
      }
      a {
        font-weight: 700;
        color: $black;
      }
    }
  }
}
.icon-klarna {
  display: inline-block;
  margin-top: 1rem;
  width: 90px;
  height: 50px;
}
</style>
