import { createSSRApp, createApp as createClientApp } from 'vue'
import { type App as VueApp } from 'vue'
import { createPinia, type Pinia } from 'pinia'
import VueGtag from 'vue-gtag'
import VueCookies from 'vue-cookies'
import {
  VueHeadMixin,
  createHead,
  type VueHeadClient,
  type MergeHead
} from '@unhead/vue'
import App from './App.vue'
import router from './router'
import { isClient } from './utils/helpers'
// import clickOutside from './utils/directives/clickOutside'
import { vOnClickOutside } from '@vueuse/components'
import iframeResize from './utils/directives/iframeResize'
import type { Router } from 'vue-router'

interface CreateApp {
  app: VueApp<any>
  router: Router
  store: Pinia
  head: VueHeadClient<MergeHead>
}

export const createApp = (): CreateApp => {
  let app: VueApp
  if (import.meta.env.DEV && !isClient) {
    app = createClientApp(App)
  } else {
    app = createSSRApp(App)
  }
  const store = createPinia()
  const head = createHead()

  app.use(head)
  app.use(store)
  app.use(router)
  app.use(
    VueGtag,
    {
      config: { id: 'AW-10790207123' },
      includes: [{ id: 'G-LKQM8YKBT6' }],
      appName: 'Djur&Natur',
      pageTrackerScreenviewEnabled: true
    },
    router
  )
  app.use(VueCookies)
  // app.directive('click-outside', clickOutside)
  app.directive('on-click-outside', vOnClickOutside)
  app.directive('resize', iframeResize)
  app.mixin(VueHeadMixin)

  return {
    app,
    router,
    store,
    head
  }
}
