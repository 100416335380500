<script lang="ts">
import { defineComponent } from 'vue'
import Icon from './Icon.vue'
import { useStore } from '../store'

export default defineComponent({
  name: 'MobileHeader',
  components: {
    Icon
  },
  setup() {
    const { setShowMobileMenu } = useStore()
    return { setShowMobileMenu }
  },
  data() {
    return {
      search: this.$route.query.q ? this.$route.query.q : null
    }
  },
  methods: {
    submit() {
      this.$router.push({ name: 'Search', query: { q: this.search } })
    }
  }
})
</script>

<template>
  <nav class="header__mobile">
    <div class="header__burger" @click.prevent="setShowMobileMenu">
      <div class="burger__inner" />
    </div>
    <form @submit.prevent="submit()">
      <label for="search-mobile">Sök efter:</label>
      <input
        id="search-mobile"
        v-model="search"
        role="search"
        type="text"
        name="q"
        placeholder="Sök produkt, varumärke, kategori..."
        autocomplete="off"
      />
      <button type="submit">
        <span>Sök</span>
        <Icon name="search" type="fas" />
      </button>
    </form>
  </nav>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
.header__mobile {
  position: relative;
  top: 0;
  left: 0;
  z-index: 3000;
  @media (min-width: 1025px) {
    display: none;
  }

  /* checkbox for burger btn */
  .header__toggler {
    position: absolute;
    top: -2.2em;
    right: 0;
    z-index: 20005;
    cursor: pointer;
    width: 45px;
    height: 45px;
    opacity: 0;
  }
  form {
    position: relative;
    label {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
    input#search-mobile {
      -webkit-appearance: none;
      width: 100%;
      height: 50px;
      border-top: 1px solid $highlight;
      border-bottom: 1px solid $highlight;
      border-left: 0;
      border-right: 0;
      border-radius: 0px;
      color: $black;
      font-family: $inter;
      font-weight: 300;
      font-size: 0.938em;
      padding: 0.5rem 3rem 0.5rem 0.875rem;
      @media (min-width: 1025px) {
        border-radius: 2px;
        border: 1px solid $highlight;
      }
      &::placeholder {
        color: $black;
        font-size: 0.8125rem;
      }
      &:focus {
        outline-color: $main;
      }
    }
    button[type='submit'] {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
      border: none;
      background: none;
      padding: 0 1.125rem 0 0.75rem;
      color: black;
      span {
        // sr-only
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      }
      svg {
        width: 1.3em;
        height: auto;
      }
    }
  }
}
</style>
