<script lang="ts">
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'

export default defineComponent({
  name: 'NoticesComponent',
  setup() {
    const store = useStore()
    const { setNotices } = useStore()
    const { notices } = storeToRefs(store)
    return { setNotices, notices }
  },
  watch: {
    notices: {
      handler() {
        if (this.notices.length > 0) {
          setTimeout(() => {
            this.setNotices({ notices: [] })
          }, 5000)
        }
      },
      immediate: true
    }
  }
})
</script>

<template>
  <transition-group class="notices" name="list" tag="ul">
    <li
      v-for="notice in notices"
      :key="notice.id"
      :class="'notice-' + notice.severity"
      v-html="notice.notice"
    ></li>
  </transition-group>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.notices {
  position: fixed;
  z-index: 2000;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  li {
    padding: 1em;
    list-style: none;
    background-color: #004999;
    font-weight: bold;
    color: white;
    :deep(*) {
      color: white !important;
    }
  }
}

.list-item {
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-40px);
}
</style>
